import './AdminHome.css';
import {Link} from 'react-router-dom';
import React from "react";

interface Props {
  children?: string,
  link?: string,
}

export const LeftNavIcon = (props: Props) => {
  return props.link ?
      <Link to={props.link}>
        <div className={'left-nav-icon'}>
          {props.children}
        </div>
      </Link>
      :
      <div className={'left-nav-icon'}>
        {props.children}
      </div>
};