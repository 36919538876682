import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {Auth0ProviderWithNavigate} from './auth0-provider-with-navigate';
import App from "./App/App";
import {Admins} from "./operator/Admins";
import {AdminProfileContext, AdminProfileProvider} from "./AdminProfileProvider";

// The react docs only work for the old style <BrowserRouter>

// NOTE: I stopped using the newer createBrowser route because the auth docs do not cover
// integration with that. I tried the following unsuccessfully
// https://stackoverflow.com/questions/73934043/how-to-use-auth0-provider-with-the-new-createbrowsererouter-in-v6-4


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <AdminProfileProvider>
            <App/>
          </AdminProfileProvider>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
