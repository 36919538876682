import {AppState, Auth0Provider, User} from '@auth0/auth0-react';
import React, {ReactNode} from 'react';
import {useNavigate} from 'react-router-dom';

interface Props {
  children?: ReactNode
}

export const Auth0ProviderWithNavigate = (props: Props) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState, user?: User) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
      <Auth0Provider
          domain="login.thymebook.com"
          clientId="xmu0AAuS6JwpYpWiP63g77Y7mhECW2lI"
          authorizationParams={{
            redirect_uri: window.location.origin,
          }}
          onRedirectCallback={onRedirectCallback}
      >
        {props.children}
      </Auth0Provider>
  );
};