/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.AdminInvitationProto = (function() {

    /**
     * Properties of an AdminInvitationProto.
     * @exports IAdminInvitationProto
     * @interface IAdminInvitationProto
     * @property {string|null} [inviteCode] AdminInvitationProto inviteCode
     * @property {number|Long|null} [generationTime] AdminInvitationProto generationTime
     * @property {boolean|null} [redeemed] AdminInvitationProto redeemed
     */

    /**
     * Constructs a new AdminInvitationProto.
     * @exports AdminInvitationProto
     * @classdesc Represents an AdminInvitationProto.
     * @implements IAdminInvitationProto
     * @constructor
     * @param {IAdminInvitationProto=} [properties] Properties to set
     */
    function AdminInvitationProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AdminInvitationProto inviteCode.
     * @member {string} inviteCode
     * @memberof AdminInvitationProto
     * @instance
     */
    AdminInvitationProto.prototype.inviteCode = "";

    /**
     * AdminInvitationProto generationTime.
     * @member {number|Long} generationTime
     * @memberof AdminInvitationProto
     * @instance
     */
    AdminInvitationProto.prototype.generationTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * AdminInvitationProto redeemed.
     * @member {boolean} redeemed
     * @memberof AdminInvitationProto
     * @instance
     */
    AdminInvitationProto.prototype.redeemed = false;

    /**
     * Creates a new AdminInvitationProto instance using the specified properties.
     * @function create
     * @memberof AdminInvitationProto
     * @static
     * @param {IAdminInvitationProto=} [properties] Properties to set
     * @returns {AdminInvitationProto} AdminInvitationProto instance
     */
    AdminInvitationProto.create = function create(properties) {
        return new AdminInvitationProto(properties);
    };

    /**
     * Encodes the specified AdminInvitationProto message. Does not implicitly {@link AdminInvitationProto.verify|verify} messages.
     * @function encode
     * @memberof AdminInvitationProto
     * @static
     * @param {IAdminInvitationProto} message AdminInvitationProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminInvitationProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.inviteCode);
        if (message.generationTime != null && Object.hasOwnProperty.call(message, "generationTime"))
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.generationTime);
        if (message.redeemed != null && Object.hasOwnProperty.call(message, "redeemed"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.redeemed);
        return writer;
    };

    /**
     * Encodes the specified AdminInvitationProto message, length delimited. Does not implicitly {@link AdminInvitationProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AdminInvitationProto
     * @static
     * @param {IAdminInvitationProto} message AdminInvitationProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AdminInvitationProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AdminInvitationProto message from the specified reader or buffer.
     * @function decode
     * @memberof AdminInvitationProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AdminInvitationProto} AdminInvitationProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminInvitationProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AdminInvitationProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.inviteCode = reader.string();
                    break;
                }
            case 2: {
                    message.generationTime = reader.int64();
                    break;
                }
            case 3: {
                    message.redeemed = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AdminInvitationProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AdminInvitationProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AdminInvitationProto} AdminInvitationProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AdminInvitationProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AdminInvitationProto message.
     * @function verify
     * @memberof AdminInvitationProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AdminInvitationProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        if (message.generationTime != null && message.hasOwnProperty("generationTime"))
            if (!$util.isInteger(message.generationTime) && !(message.generationTime && $util.isInteger(message.generationTime.low) && $util.isInteger(message.generationTime.high)))
                return "generationTime: integer|Long expected";
        if (message.redeemed != null && message.hasOwnProperty("redeemed"))
            if (typeof message.redeemed !== "boolean")
                return "redeemed: boolean expected";
        return null;
    };

    /**
     * Creates an AdminInvitationProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AdminInvitationProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AdminInvitationProto} AdminInvitationProto
     */
    AdminInvitationProto.fromObject = function fromObject(object) {
        if (object instanceof $root.AdminInvitationProto)
            return object;
        var message = new $root.AdminInvitationProto();
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        if (object.generationTime != null)
            if ($util.Long)
                (message.generationTime = $util.Long.fromValue(object.generationTime)).unsigned = false;
            else if (typeof object.generationTime === "string")
                message.generationTime = parseInt(object.generationTime, 10);
            else if (typeof object.generationTime === "number")
                message.generationTime = object.generationTime;
            else if (typeof object.generationTime === "object")
                message.generationTime = new $util.LongBits(object.generationTime.low >>> 0, object.generationTime.high >>> 0).toNumber();
        if (object.redeemed != null)
            message.redeemed = Boolean(object.redeemed);
        return message;
    };

    /**
     * Creates a plain object from an AdminInvitationProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AdminInvitationProto
     * @static
     * @param {AdminInvitationProto} message AdminInvitationProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AdminInvitationProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.inviteCode = "";
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.generationTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.generationTime = options.longs === String ? "0" : 0;
            object.redeemed = false;
        }
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        if (message.generationTime != null && message.hasOwnProperty("generationTime"))
            if (typeof message.generationTime === "number")
                object.generationTime = options.longs === String ? String(message.generationTime) : message.generationTime;
            else
                object.generationTime = options.longs === String ? $util.Long.prototype.toString.call(message.generationTime) : options.longs === Number ? new $util.LongBits(message.generationTime.low >>> 0, message.generationTime.high >>> 0).toNumber() : message.generationTime;
        if (message.redeemed != null && message.hasOwnProperty("redeemed"))
            object.redeemed = message.redeemed;
        return object;
    };

    /**
     * Converts this AdminInvitationProto to JSON.
     * @function toJSON
     * @memberof AdminInvitationProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AdminInvitationProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AdminInvitationProto
     * @function getTypeUrl
     * @memberof AdminInvitationProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AdminInvitationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AdminInvitationProto";
    };

    return AdminInvitationProto;
})();

$root.CheckAdminRoleRequest = (function() {

    /**
     * Properties of a CheckAdminRoleRequest.
     * @exports ICheckAdminRoleRequest
     * @interface ICheckAdminRoleRequest
     */

    /**
     * Constructs a new CheckAdminRoleRequest.
     * @exports CheckAdminRoleRequest
     * @classdesc Represents a CheckAdminRoleRequest.
     * @implements ICheckAdminRoleRequest
     * @constructor
     * @param {ICheckAdminRoleRequest=} [properties] Properties to set
     */
    function CheckAdminRoleRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CheckAdminRoleRequest instance using the specified properties.
     * @function create
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {ICheckAdminRoleRequest=} [properties] Properties to set
     * @returns {CheckAdminRoleRequest} CheckAdminRoleRequest instance
     */
    CheckAdminRoleRequest.create = function create(properties) {
        return new CheckAdminRoleRequest(properties);
    };

    /**
     * Encodes the specified CheckAdminRoleRequest message. Does not implicitly {@link CheckAdminRoleRequest.verify|verify} messages.
     * @function encode
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {ICheckAdminRoleRequest} message CheckAdminRoleRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckAdminRoleRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified CheckAdminRoleRequest message, length delimited. Does not implicitly {@link CheckAdminRoleRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {ICheckAdminRoleRequest} message CheckAdminRoleRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckAdminRoleRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckAdminRoleRequest message from the specified reader or buffer.
     * @function decode
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckAdminRoleRequest} CheckAdminRoleRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckAdminRoleRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckAdminRoleRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckAdminRoleRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckAdminRoleRequest} CheckAdminRoleRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckAdminRoleRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckAdminRoleRequest message.
     * @function verify
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckAdminRoleRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a CheckAdminRoleRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckAdminRoleRequest} CheckAdminRoleRequest
     */
    CheckAdminRoleRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckAdminRoleRequest)
            return object;
        return new $root.CheckAdminRoleRequest();
    };

    /**
     * Creates a plain object from a CheckAdminRoleRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {CheckAdminRoleRequest} message CheckAdminRoleRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckAdminRoleRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this CheckAdminRoleRequest to JSON.
     * @function toJSON
     * @memberof CheckAdminRoleRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckAdminRoleRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckAdminRoleRequest
     * @function getTypeUrl
     * @memberof CheckAdminRoleRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckAdminRoleRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckAdminRoleRequest";
    };

    return CheckAdminRoleRequest;
})();

$root.CheckAdminRoleResponse = (function() {

    /**
     * Properties of a CheckAdminRoleResponse.
     * @exports ICheckAdminRoleResponse
     * @interface ICheckAdminRoleResponse
     * @property {boolean|null} [okay] CheckAdminRoleResponse okay
     * @property {boolean|null} [isAdmin] CheckAdminRoleResponse isAdmin
     */

    /**
     * Constructs a new CheckAdminRoleResponse.
     * @exports CheckAdminRoleResponse
     * @classdesc Represents a CheckAdminRoleResponse.
     * @implements ICheckAdminRoleResponse
     * @constructor
     * @param {ICheckAdminRoleResponse=} [properties] Properties to set
     */
    function CheckAdminRoleResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckAdminRoleResponse okay.
     * @member {boolean} okay
     * @memberof CheckAdminRoleResponse
     * @instance
     */
    CheckAdminRoleResponse.prototype.okay = false;

    /**
     * CheckAdminRoleResponse isAdmin.
     * @member {boolean} isAdmin
     * @memberof CheckAdminRoleResponse
     * @instance
     */
    CheckAdminRoleResponse.prototype.isAdmin = false;

    /**
     * Creates a new CheckAdminRoleResponse instance using the specified properties.
     * @function create
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {ICheckAdminRoleResponse=} [properties] Properties to set
     * @returns {CheckAdminRoleResponse} CheckAdminRoleResponse instance
     */
    CheckAdminRoleResponse.create = function create(properties) {
        return new CheckAdminRoleResponse(properties);
    };

    /**
     * Encodes the specified CheckAdminRoleResponse message. Does not implicitly {@link CheckAdminRoleResponse.verify|verify} messages.
     * @function encode
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {ICheckAdminRoleResponse} message CheckAdminRoleResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckAdminRoleResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.isAdmin != null && Object.hasOwnProperty.call(message, "isAdmin"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isAdmin);
        return writer;
    };

    /**
     * Encodes the specified CheckAdminRoleResponse message, length delimited. Does not implicitly {@link CheckAdminRoleResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {ICheckAdminRoleResponse} message CheckAdminRoleResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckAdminRoleResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckAdminRoleResponse message from the specified reader or buffer.
     * @function decode
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckAdminRoleResponse} CheckAdminRoleResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckAdminRoleResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckAdminRoleResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.isAdmin = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckAdminRoleResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckAdminRoleResponse} CheckAdminRoleResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckAdminRoleResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckAdminRoleResponse message.
     * @function verify
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckAdminRoleResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.isAdmin != null && message.hasOwnProperty("isAdmin"))
            if (typeof message.isAdmin !== "boolean")
                return "isAdmin: boolean expected";
        return null;
    };

    /**
     * Creates a CheckAdminRoleResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckAdminRoleResponse} CheckAdminRoleResponse
     */
    CheckAdminRoleResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckAdminRoleResponse)
            return object;
        var message = new $root.CheckAdminRoleResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.isAdmin != null)
            message.isAdmin = Boolean(object.isAdmin);
        return message;
    };

    /**
     * Creates a plain object from a CheckAdminRoleResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {CheckAdminRoleResponse} message CheckAdminRoleResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckAdminRoleResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.isAdmin = false;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.isAdmin != null && message.hasOwnProperty("isAdmin"))
            object.isAdmin = message.isAdmin;
        return object;
    };

    /**
     * Converts this CheckAdminRoleResponse to JSON.
     * @function toJSON
     * @memberof CheckAdminRoleResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckAdminRoleResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckAdminRoleResponse
     * @function getTypeUrl
     * @memberof CheckAdminRoleResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckAdminRoleResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckAdminRoleResponse";
    };

    return CheckAdminRoleResponse;
})();

$root.GenerateInvitationRequest = (function() {

    /**
     * Properties of a GenerateInvitationRequest.
     * @exports IGenerateInvitationRequest
     * @interface IGenerateInvitationRequest
     * @property {number|null} [num] GenerateInvitationRequest num
     */

    /**
     * Constructs a new GenerateInvitationRequest.
     * @exports GenerateInvitationRequest
     * @classdesc Represents a GenerateInvitationRequest.
     * @implements IGenerateInvitationRequest
     * @constructor
     * @param {IGenerateInvitationRequest=} [properties] Properties to set
     */
    function GenerateInvitationRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GenerateInvitationRequest num.
     * @member {number} num
     * @memberof GenerateInvitationRequest
     * @instance
     */
    GenerateInvitationRequest.prototype.num = 0;

    /**
     * Creates a new GenerateInvitationRequest instance using the specified properties.
     * @function create
     * @memberof GenerateInvitationRequest
     * @static
     * @param {IGenerateInvitationRequest=} [properties] Properties to set
     * @returns {GenerateInvitationRequest} GenerateInvitationRequest instance
     */
    GenerateInvitationRequest.create = function create(properties) {
        return new GenerateInvitationRequest(properties);
    };

    /**
     * Encodes the specified GenerateInvitationRequest message. Does not implicitly {@link GenerateInvitationRequest.verify|verify} messages.
     * @function encode
     * @memberof GenerateInvitationRequest
     * @static
     * @param {IGenerateInvitationRequest} message GenerateInvitationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateInvitationRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.num != null && Object.hasOwnProperty.call(message, "num"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.num);
        return writer;
    };

    /**
     * Encodes the specified GenerateInvitationRequest message, length delimited. Does not implicitly {@link GenerateInvitationRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GenerateInvitationRequest
     * @static
     * @param {IGenerateInvitationRequest} message GenerateInvitationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateInvitationRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GenerateInvitationRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GenerateInvitationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GenerateInvitationRequest} GenerateInvitationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateInvitationRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GenerateInvitationRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.num = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GenerateInvitationRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GenerateInvitationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GenerateInvitationRequest} GenerateInvitationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateInvitationRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GenerateInvitationRequest message.
     * @function verify
     * @memberof GenerateInvitationRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GenerateInvitationRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.num != null && message.hasOwnProperty("num"))
            if (!$util.isInteger(message.num))
                return "num: integer expected";
        return null;
    };

    /**
     * Creates a GenerateInvitationRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GenerateInvitationRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GenerateInvitationRequest} GenerateInvitationRequest
     */
    GenerateInvitationRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GenerateInvitationRequest)
            return object;
        var message = new $root.GenerateInvitationRequest();
        if (object.num != null)
            message.num = object.num | 0;
        return message;
    };

    /**
     * Creates a plain object from a GenerateInvitationRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GenerateInvitationRequest
     * @static
     * @param {GenerateInvitationRequest} message GenerateInvitationRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GenerateInvitationRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.num = 0;
        if (message.num != null && message.hasOwnProperty("num"))
            object.num = message.num;
        return object;
    };

    /**
     * Converts this GenerateInvitationRequest to JSON.
     * @function toJSON
     * @memberof GenerateInvitationRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GenerateInvitationRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GenerateInvitationRequest
     * @function getTypeUrl
     * @memberof GenerateInvitationRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GenerateInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GenerateInvitationRequest";
    };

    return GenerateInvitationRequest;
})();

$root.GenerateInvitationResponse = (function() {

    /**
     * Properties of a GenerateInvitationResponse.
     * @exports IGenerateInvitationResponse
     * @interface IGenerateInvitationResponse
     * @property {boolean|null} [okay] GenerateInvitationResponse okay
     * @property {Array.<string>|null} [inviteCodes] GenerateInvitationResponse inviteCodes
     */

    /**
     * Constructs a new GenerateInvitationResponse.
     * @exports GenerateInvitationResponse
     * @classdesc Represents a GenerateInvitationResponse.
     * @implements IGenerateInvitationResponse
     * @constructor
     * @param {IGenerateInvitationResponse=} [properties] Properties to set
     */
    function GenerateInvitationResponse(properties) {
        this.inviteCodes = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GenerateInvitationResponse okay.
     * @member {boolean} okay
     * @memberof GenerateInvitationResponse
     * @instance
     */
    GenerateInvitationResponse.prototype.okay = false;

    /**
     * GenerateInvitationResponse inviteCodes.
     * @member {Array.<string>} inviteCodes
     * @memberof GenerateInvitationResponse
     * @instance
     */
    GenerateInvitationResponse.prototype.inviteCodes = $util.emptyArray;

    /**
     * Creates a new GenerateInvitationResponse instance using the specified properties.
     * @function create
     * @memberof GenerateInvitationResponse
     * @static
     * @param {IGenerateInvitationResponse=} [properties] Properties to set
     * @returns {GenerateInvitationResponse} GenerateInvitationResponse instance
     */
    GenerateInvitationResponse.create = function create(properties) {
        return new GenerateInvitationResponse(properties);
    };

    /**
     * Encodes the specified GenerateInvitationResponse message. Does not implicitly {@link GenerateInvitationResponse.verify|verify} messages.
     * @function encode
     * @memberof GenerateInvitationResponse
     * @static
     * @param {IGenerateInvitationResponse} message GenerateInvitationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateInvitationResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.inviteCodes != null && message.inviteCodes.length)
            for (var i = 0; i < message.inviteCodes.length; ++i)
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.inviteCodes[i]);
        return writer;
    };

    /**
     * Encodes the specified GenerateInvitationResponse message, length delimited. Does not implicitly {@link GenerateInvitationResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GenerateInvitationResponse
     * @static
     * @param {IGenerateInvitationResponse} message GenerateInvitationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenerateInvitationResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GenerateInvitationResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GenerateInvitationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GenerateInvitationResponse} GenerateInvitationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateInvitationResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GenerateInvitationResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.inviteCodes && message.inviteCodes.length))
                        message.inviteCodes = [];
                    message.inviteCodes.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GenerateInvitationResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GenerateInvitationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GenerateInvitationResponse} GenerateInvitationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenerateInvitationResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GenerateInvitationResponse message.
     * @function verify
     * @memberof GenerateInvitationResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GenerateInvitationResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.inviteCodes != null && message.hasOwnProperty("inviteCodes")) {
            if (!Array.isArray(message.inviteCodes))
                return "inviteCodes: array expected";
            for (var i = 0; i < message.inviteCodes.length; ++i)
                if (!$util.isString(message.inviteCodes[i]))
                    return "inviteCodes: string[] expected";
        }
        return null;
    };

    /**
     * Creates a GenerateInvitationResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GenerateInvitationResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GenerateInvitationResponse} GenerateInvitationResponse
     */
    GenerateInvitationResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GenerateInvitationResponse)
            return object;
        var message = new $root.GenerateInvitationResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.inviteCodes) {
            if (!Array.isArray(object.inviteCodes))
                throw TypeError(".GenerateInvitationResponse.inviteCodes: array expected");
            message.inviteCodes = [];
            for (var i = 0; i < object.inviteCodes.length; ++i)
                message.inviteCodes[i] = String(object.inviteCodes[i]);
        }
        return message;
    };

    /**
     * Creates a plain object from a GenerateInvitationResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GenerateInvitationResponse
     * @static
     * @param {GenerateInvitationResponse} message GenerateInvitationResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GenerateInvitationResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.inviteCodes = [];
        if (options.defaults)
            object.okay = false;
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.inviteCodes && message.inviteCodes.length) {
            object.inviteCodes = [];
            for (var j = 0; j < message.inviteCodes.length; ++j)
                object.inviteCodes[j] = message.inviteCodes[j];
        }
        return object;
    };

    /**
     * Converts this GenerateInvitationResponse to JSON.
     * @function toJSON
     * @memberof GenerateInvitationResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GenerateInvitationResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GenerateInvitationResponse
     * @function getTypeUrl
     * @memberof GenerateInvitationResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GenerateInvitationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GenerateInvitationResponse";
    };

    return GenerateInvitationResponse;
})();

$root.GetExistingInvitationsRequest = (function() {

    /**
     * Properties of a GetExistingInvitationsRequest.
     * @exports IGetExistingInvitationsRequest
     * @interface IGetExistingInvitationsRequest
     * @property {string|null} [networkId] GetExistingInvitationsRequest networkId
     */

    /**
     * Constructs a new GetExistingInvitationsRequest.
     * @exports GetExistingInvitationsRequest
     * @classdesc Represents a GetExistingInvitationsRequest.
     * @implements IGetExistingInvitationsRequest
     * @constructor
     * @param {IGetExistingInvitationsRequest=} [properties] Properties to set
     */
    function GetExistingInvitationsRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetExistingInvitationsRequest networkId.
     * @member {string} networkId
     * @memberof GetExistingInvitationsRequest
     * @instance
     */
    GetExistingInvitationsRequest.prototype.networkId = "";

    /**
     * Creates a new GetExistingInvitationsRequest instance using the specified properties.
     * @function create
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {IGetExistingInvitationsRequest=} [properties] Properties to set
     * @returns {GetExistingInvitationsRequest} GetExistingInvitationsRequest instance
     */
    GetExistingInvitationsRequest.create = function create(properties) {
        return new GetExistingInvitationsRequest(properties);
    };

    /**
     * Encodes the specified GetExistingInvitationsRequest message. Does not implicitly {@link GetExistingInvitationsRequest.verify|verify} messages.
     * @function encode
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {IGetExistingInvitationsRequest} message GetExistingInvitationsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetExistingInvitationsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.networkId != null && Object.hasOwnProperty.call(message, "networkId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.networkId);
        return writer;
    };

    /**
     * Encodes the specified GetExistingInvitationsRequest message, length delimited. Does not implicitly {@link GetExistingInvitationsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {IGetExistingInvitationsRequest} message GetExistingInvitationsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetExistingInvitationsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetExistingInvitationsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetExistingInvitationsRequest} GetExistingInvitationsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetExistingInvitationsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetExistingInvitationsRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.networkId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetExistingInvitationsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetExistingInvitationsRequest} GetExistingInvitationsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetExistingInvitationsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetExistingInvitationsRequest message.
     * @function verify
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetExistingInvitationsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.networkId != null && message.hasOwnProperty("networkId"))
            if (!$util.isString(message.networkId))
                return "networkId: string expected";
        return null;
    };

    /**
     * Creates a GetExistingInvitationsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetExistingInvitationsRequest} GetExistingInvitationsRequest
     */
    GetExistingInvitationsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetExistingInvitationsRequest)
            return object;
        var message = new $root.GetExistingInvitationsRequest();
        if (object.networkId != null)
            message.networkId = String(object.networkId);
        return message;
    };

    /**
     * Creates a plain object from a GetExistingInvitationsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {GetExistingInvitationsRequest} message GetExistingInvitationsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetExistingInvitationsRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.networkId = "";
        if (message.networkId != null && message.hasOwnProperty("networkId"))
            object.networkId = message.networkId;
        return object;
    };

    /**
     * Converts this GetExistingInvitationsRequest to JSON.
     * @function toJSON
     * @memberof GetExistingInvitationsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetExistingInvitationsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetExistingInvitationsRequest
     * @function getTypeUrl
     * @memberof GetExistingInvitationsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetExistingInvitationsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetExistingInvitationsRequest";
    };

    return GetExistingInvitationsRequest;
})();

$root.GetExistingInvitationsResponse = (function() {

    /**
     * Properties of a GetExistingInvitationsResponse.
     * @exports IGetExistingInvitationsResponse
     * @interface IGetExistingInvitationsResponse
     * @property {boolean|null} [okay] GetExistingInvitationsResponse okay
     * @property {IAdminInvitationProto|null} [invitation] GetExistingInvitationsResponse invitation
     */

    /**
     * Constructs a new GetExistingInvitationsResponse.
     * @exports GetExistingInvitationsResponse
     * @classdesc Represents a GetExistingInvitationsResponse.
     * @implements IGetExistingInvitationsResponse
     * @constructor
     * @param {IGetExistingInvitationsResponse=} [properties] Properties to set
     */
    function GetExistingInvitationsResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetExistingInvitationsResponse okay.
     * @member {boolean} okay
     * @memberof GetExistingInvitationsResponse
     * @instance
     */
    GetExistingInvitationsResponse.prototype.okay = false;

    /**
     * GetExistingInvitationsResponse invitation.
     * @member {IAdminInvitationProto|null|undefined} invitation
     * @memberof GetExistingInvitationsResponse
     * @instance
     */
    GetExistingInvitationsResponse.prototype.invitation = null;

    /**
     * Creates a new GetExistingInvitationsResponse instance using the specified properties.
     * @function create
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {IGetExistingInvitationsResponse=} [properties] Properties to set
     * @returns {GetExistingInvitationsResponse} GetExistingInvitationsResponse instance
     */
    GetExistingInvitationsResponse.create = function create(properties) {
        return new GetExistingInvitationsResponse(properties);
    };

    /**
     * Encodes the specified GetExistingInvitationsResponse message. Does not implicitly {@link GetExistingInvitationsResponse.verify|verify} messages.
     * @function encode
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {IGetExistingInvitationsResponse} message GetExistingInvitationsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetExistingInvitationsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.invitation != null && Object.hasOwnProperty.call(message, "invitation"))
            $root.AdminInvitationProto.encode(message.invitation, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetExistingInvitationsResponse message, length delimited. Does not implicitly {@link GetExistingInvitationsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {IGetExistingInvitationsResponse} message GetExistingInvitationsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetExistingInvitationsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetExistingInvitationsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetExistingInvitationsResponse} GetExistingInvitationsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetExistingInvitationsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetExistingInvitationsResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.invitation = $root.AdminInvitationProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetExistingInvitationsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetExistingInvitationsResponse} GetExistingInvitationsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetExistingInvitationsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetExistingInvitationsResponse message.
     * @function verify
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetExistingInvitationsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.invitation != null && message.hasOwnProperty("invitation")) {
            var error = $root.AdminInvitationProto.verify(message.invitation);
            if (error)
                return "invitation." + error;
        }
        return null;
    };

    /**
     * Creates a GetExistingInvitationsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetExistingInvitationsResponse} GetExistingInvitationsResponse
     */
    GetExistingInvitationsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetExistingInvitationsResponse)
            return object;
        var message = new $root.GetExistingInvitationsResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.invitation != null) {
            if (typeof object.invitation !== "object")
                throw TypeError(".GetExistingInvitationsResponse.invitation: object expected");
            message.invitation = $root.AdminInvitationProto.fromObject(object.invitation);
        }
        return message;
    };

    /**
     * Creates a plain object from a GetExistingInvitationsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {GetExistingInvitationsResponse} message GetExistingInvitationsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetExistingInvitationsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.invitation = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.invitation != null && message.hasOwnProperty("invitation"))
            object.invitation = $root.AdminInvitationProto.toObject(message.invitation, options);
        return object;
    };

    /**
     * Converts this GetExistingInvitationsResponse to JSON.
     * @function toJSON
     * @memberof GetExistingInvitationsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetExistingInvitationsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetExistingInvitationsResponse
     * @function getTypeUrl
     * @memberof GetExistingInvitationsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetExistingInvitationsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetExistingInvitationsResponse";
    };

    return GetExistingInvitationsResponse;
})();

$root.UpdateSettingsRequest = (function() {

    /**
     * Properties of an UpdateSettingsRequest.
     * @exports IUpdateSettingsRequest
     * @interface IUpdateSettingsRequest
     * @property {space.kenko.proto.ISettingsProto|null} [settings] UpdateSettingsRequest settings
     */

    /**
     * Constructs a new UpdateSettingsRequest.
     * @exports UpdateSettingsRequest
     * @classdesc Represents an UpdateSettingsRequest.
     * @implements IUpdateSettingsRequest
     * @constructor
     * @param {IUpdateSettingsRequest=} [properties] Properties to set
     */
    function UpdateSettingsRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateSettingsRequest settings.
     * @member {space.kenko.proto.ISettingsProto|null|undefined} settings
     * @memberof UpdateSettingsRequest
     * @instance
     */
    UpdateSettingsRequest.prototype.settings = null;

    /**
     * Creates a new UpdateSettingsRequest instance using the specified properties.
     * @function create
     * @memberof UpdateSettingsRequest
     * @static
     * @param {IUpdateSettingsRequest=} [properties] Properties to set
     * @returns {UpdateSettingsRequest} UpdateSettingsRequest instance
     */
    UpdateSettingsRequest.create = function create(properties) {
        return new UpdateSettingsRequest(properties);
    };

    /**
     * Encodes the specified UpdateSettingsRequest message. Does not implicitly {@link UpdateSettingsRequest.verify|verify} messages.
     * @function encode
     * @memberof UpdateSettingsRequest
     * @static
     * @param {IUpdateSettingsRequest} message UpdateSettingsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSettingsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
            $root.space.kenko.proto.SettingsProto.encode(message.settings, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateSettingsRequest message, length delimited. Does not implicitly {@link UpdateSettingsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateSettingsRequest
     * @static
     * @param {IUpdateSettingsRequest} message UpdateSettingsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSettingsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateSettingsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateSettingsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateSettingsRequest} UpdateSettingsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSettingsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateSettingsRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.settings = $root.space.kenko.proto.SettingsProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateSettingsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateSettingsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateSettingsRequest} UpdateSettingsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSettingsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateSettingsRequest message.
     * @function verify
     * @memberof UpdateSettingsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateSettingsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.settings != null && message.hasOwnProperty("settings")) {
            var error = $root.space.kenko.proto.SettingsProto.verify(message.settings);
            if (error)
                return "settings." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateSettingsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateSettingsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateSettingsRequest} UpdateSettingsRequest
     */
    UpdateSettingsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateSettingsRequest)
            return object;
        var message = new $root.UpdateSettingsRequest();
        if (object.settings != null) {
            if (typeof object.settings !== "object")
                throw TypeError(".UpdateSettingsRequest.settings: object expected");
            message.settings = $root.space.kenko.proto.SettingsProto.fromObject(object.settings);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateSettingsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateSettingsRequest
     * @static
     * @param {UpdateSettingsRequest} message UpdateSettingsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateSettingsRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.settings = null;
        if (message.settings != null && message.hasOwnProperty("settings"))
            object.settings = $root.space.kenko.proto.SettingsProto.toObject(message.settings, options);
        return object;
    };

    /**
     * Converts this UpdateSettingsRequest to JSON.
     * @function toJSON
     * @memberof UpdateSettingsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateSettingsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateSettingsRequest
     * @function getTypeUrl
     * @memberof UpdateSettingsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateSettingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateSettingsRequest";
    };

    return UpdateSettingsRequest;
})();

$root.UpdateSettingsResponse = (function() {

    /**
     * Properties of an UpdateSettingsResponse.
     * @exports IUpdateSettingsResponse
     * @interface IUpdateSettingsResponse
     * @property {boolean|null} [okay] UpdateSettingsResponse okay
     * @property {space.kenko.proto.ISettingsProto|null} [newSettings] UpdateSettingsResponse newSettings
     */

    /**
     * Constructs a new UpdateSettingsResponse.
     * @exports UpdateSettingsResponse
     * @classdesc Represents an UpdateSettingsResponse.
     * @implements IUpdateSettingsResponse
     * @constructor
     * @param {IUpdateSettingsResponse=} [properties] Properties to set
     */
    function UpdateSettingsResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateSettingsResponse okay.
     * @member {boolean} okay
     * @memberof UpdateSettingsResponse
     * @instance
     */
    UpdateSettingsResponse.prototype.okay = false;

    /**
     * UpdateSettingsResponse newSettings.
     * @member {space.kenko.proto.ISettingsProto|null|undefined} newSettings
     * @memberof UpdateSettingsResponse
     * @instance
     */
    UpdateSettingsResponse.prototype.newSettings = null;

    /**
     * Creates a new UpdateSettingsResponse instance using the specified properties.
     * @function create
     * @memberof UpdateSettingsResponse
     * @static
     * @param {IUpdateSettingsResponse=} [properties] Properties to set
     * @returns {UpdateSettingsResponse} UpdateSettingsResponse instance
     */
    UpdateSettingsResponse.create = function create(properties) {
        return new UpdateSettingsResponse(properties);
    };

    /**
     * Encodes the specified UpdateSettingsResponse message. Does not implicitly {@link UpdateSettingsResponse.verify|verify} messages.
     * @function encode
     * @memberof UpdateSettingsResponse
     * @static
     * @param {IUpdateSettingsResponse} message UpdateSettingsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSettingsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.newSettings != null && Object.hasOwnProperty.call(message, "newSettings"))
            $root.space.kenko.proto.SettingsProto.encode(message.newSettings, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateSettingsResponse message, length delimited. Does not implicitly {@link UpdateSettingsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateSettingsResponse
     * @static
     * @param {IUpdateSettingsResponse} message UpdateSettingsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateSettingsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateSettingsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateSettingsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateSettingsResponse} UpdateSettingsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSettingsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateSettingsResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.newSettings = $root.space.kenko.proto.SettingsProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateSettingsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateSettingsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateSettingsResponse} UpdateSettingsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateSettingsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateSettingsResponse message.
     * @function verify
     * @memberof UpdateSettingsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateSettingsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.newSettings != null && message.hasOwnProperty("newSettings")) {
            var error = $root.space.kenko.proto.SettingsProto.verify(message.newSettings);
            if (error)
                return "newSettings." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateSettingsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateSettingsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateSettingsResponse} UpdateSettingsResponse
     */
    UpdateSettingsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateSettingsResponse)
            return object;
        var message = new $root.UpdateSettingsResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.newSettings != null) {
            if (typeof object.newSettings !== "object")
                throw TypeError(".UpdateSettingsResponse.newSettings: object expected");
            message.newSettings = $root.space.kenko.proto.SettingsProto.fromObject(object.newSettings);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateSettingsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateSettingsResponse
     * @static
     * @param {UpdateSettingsResponse} message UpdateSettingsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateSettingsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.newSettings = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.newSettings != null && message.hasOwnProperty("newSettings"))
            object.newSettings = $root.space.kenko.proto.SettingsProto.toObject(message.newSettings, options);
        return object;
    };

    /**
     * Converts this UpdateSettingsResponse to JSON.
     * @function toJSON
     * @memberof UpdateSettingsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateSettingsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateSettingsResponse
     * @function getTypeUrl
     * @memberof UpdateSettingsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateSettingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateSettingsResponse";
    };

    return UpdateSettingsResponse;
})();

$root.GetSettingsRequest = (function() {

    /**
     * Properties of a GetSettingsRequest.
     * @exports IGetSettingsRequest
     * @interface IGetSettingsRequest
     */

    /**
     * Constructs a new GetSettingsRequest.
     * @exports GetSettingsRequest
     * @classdesc Represents a GetSettingsRequest.
     * @implements IGetSettingsRequest
     * @constructor
     * @param {IGetSettingsRequest=} [properties] Properties to set
     */
    function GetSettingsRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetSettingsRequest instance using the specified properties.
     * @function create
     * @memberof GetSettingsRequest
     * @static
     * @param {IGetSettingsRequest=} [properties] Properties to set
     * @returns {GetSettingsRequest} GetSettingsRequest instance
     */
    GetSettingsRequest.create = function create(properties) {
        return new GetSettingsRequest(properties);
    };

    /**
     * Encodes the specified GetSettingsRequest message. Does not implicitly {@link GetSettingsRequest.verify|verify} messages.
     * @function encode
     * @memberof GetSettingsRequest
     * @static
     * @param {IGetSettingsRequest} message GetSettingsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSettingsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified GetSettingsRequest message, length delimited. Does not implicitly {@link GetSettingsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetSettingsRequest
     * @static
     * @param {IGetSettingsRequest} message GetSettingsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSettingsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetSettingsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetSettingsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetSettingsRequest} GetSettingsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSettingsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetSettingsRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetSettingsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetSettingsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetSettingsRequest} GetSettingsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSettingsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetSettingsRequest message.
     * @function verify
     * @memberof GetSettingsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetSettingsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a GetSettingsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetSettingsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetSettingsRequest} GetSettingsRequest
     */
    GetSettingsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetSettingsRequest)
            return object;
        return new $root.GetSettingsRequest();
    };

    /**
     * Creates a plain object from a GetSettingsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetSettingsRequest
     * @static
     * @param {GetSettingsRequest} message GetSettingsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetSettingsRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this GetSettingsRequest to JSON.
     * @function toJSON
     * @memberof GetSettingsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetSettingsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetSettingsRequest
     * @function getTypeUrl
     * @memberof GetSettingsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetSettingsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetSettingsRequest";
    };

    return GetSettingsRequest;
})();

$root.GetSettingsResponse = (function() {

    /**
     * Properties of a GetSettingsResponse.
     * @exports IGetSettingsResponse
     * @interface IGetSettingsResponse
     * @property {boolean|null} [okay] GetSettingsResponse okay
     * @property {space.kenko.proto.ISettingsProto|null} [newSettings] GetSettingsResponse newSettings
     */

    /**
     * Constructs a new GetSettingsResponse.
     * @exports GetSettingsResponse
     * @classdesc Represents a GetSettingsResponse.
     * @implements IGetSettingsResponse
     * @constructor
     * @param {IGetSettingsResponse=} [properties] Properties to set
     */
    function GetSettingsResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetSettingsResponse okay.
     * @member {boolean} okay
     * @memberof GetSettingsResponse
     * @instance
     */
    GetSettingsResponse.prototype.okay = false;

    /**
     * GetSettingsResponse newSettings.
     * @member {space.kenko.proto.ISettingsProto|null|undefined} newSettings
     * @memberof GetSettingsResponse
     * @instance
     */
    GetSettingsResponse.prototype.newSettings = null;

    /**
     * Creates a new GetSettingsResponse instance using the specified properties.
     * @function create
     * @memberof GetSettingsResponse
     * @static
     * @param {IGetSettingsResponse=} [properties] Properties to set
     * @returns {GetSettingsResponse} GetSettingsResponse instance
     */
    GetSettingsResponse.create = function create(properties) {
        return new GetSettingsResponse(properties);
    };

    /**
     * Encodes the specified GetSettingsResponse message. Does not implicitly {@link GetSettingsResponse.verify|verify} messages.
     * @function encode
     * @memberof GetSettingsResponse
     * @static
     * @param {IGetSettingsResponse} message GetSettingsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSettingsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.newSettings != null && Object.hasOwnProperty.call(message, "newSettings"))
            $root.space.kenko.proto.SettingsProto.encode(message.newSettings, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetSettingsResponse message, length delimited. Does not implicitly {@link GetSettingsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetSettingsResponse
     * @static
     * @param {IGetSettingsResponse} message GetSettingsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetSettingsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetSettingsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetSettingsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetSettingsResponse} GetSettingsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSettingsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetSettingsResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.newSettings = $root.space.kenko.proto.SettingsProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetSettingsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetSettingsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetSettingsResponse} GetSettingsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetSettingsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetSettingsResponse message.
     * @function verify
     * @memberof GetSettingsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetSettingsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.newSettings != null && message.hasOwnProperty("newSettings")) {
            var error = $root.space.kenko.proto.SettingsProto.verify(message.newSettings);
            if (error)
                return "newSettings." + error;
        }
        return null;
    };

    /**
     * Creates a GetSettingsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetSettingsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetSettingsResponse} GetSettingsResponse
     */
    GetSettingsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetSettingsResponse)
            return object;
        var message = new $root.GetSettingsResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.newSettings != null) {
            if (typeof object.newSettings !== "object")
                throw TypeError(".GetSettingsResponse.newSettings: object expected");
            message.newSettings = $root.space.kenko.proto.SettingsProto.fromObject(object.newSettings);
        }
        return message;
    };

    /**
     * Creates a plain object from a GetSettingsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetSettingsResponse
     * @static
     * @param {GetSettingsResponse} message GetSettingsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetSettingsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.newSettings = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.newSettings != null && message.hasOwnProperty("newSettings"))
            object.newSettings = $root.space.kenko.proto.SettingsProto.toObject(message.newSettings, options);
        return object;
    };

    /**
     * Converts this GetSettingsResponse to JSON.
     * @function toJSON
     * @memberof GetSettingsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetSettingsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetSettingsResponse
     * @function getTypeUrl
     * @memberof GetSettingsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetSettingsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetSettingsResponse";
    };

    return GetSettingsResponse;
})();

$root.space = (function() {

    /**
     * Namespace space.
     * @exports space
     * @namespace
     */
    var space = {};

    space.kenko = (function() {

        /**
         * Namespace kenko.
         * @memberof space
         * @namespace
         */
        var kenko = {};

        kenko.proto = (function() {

            /**
             * Namespace proto.
             * @memberof space.kenko
             * @namespace
             */
            var proto = {};

            proto.SettingsProto = (function() {

                /**
                 * Properties of a SettingsProto.
                 * @memberof space.kenko.proto
                 * @interface ISettingsProto
                 * @property {string|null} [llmModel] SettingsProto llmModel
                 * @property {string|null} [llmSystemPrompt] SettingsProto llmSystemPrompt
                 * @property {string|null} [llmBackend] SettingsProto llmBackend
                 */

                /**
                 * Constructs a new SettingsProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a SettingsProto.
                 * @implements ISettingsProto
                 * @constructor
                 * @param {space.kenko.proto.ISettingsProto=} [properties] Properties to set
                 */
                function SettingsProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SettingsProto llmModel.
                 * @member {string} llmModel
                 * @memberof space.kenko.proto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.llmModel = "";

                /**
                 * SettingsProto llmSystemPrompt.
                 * @member {string} llmSystemPrompt
                 * @memberof space.kenko.proto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.llmSystemPrompt = "";

                /**
                 * SettingsProto llmBackend.
                 * @member {string} llmBackend
                 * @memberof space.kenko.proto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.llmBackend = "";

                /**
                 * Creates a new SettingsProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {space.kenko.proto.ISettingsProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.SettingsProto} SettingsProto instance
                 */
                SettingsProto.create = function create(properties) {
                    return new SettingsProto(properties);
                };

                /**
                 * Encodes the specified SettingsProto message. Does not implicitly {@link space.kenko.proto.SettingsProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {space.kenko.proto.ISettingsProto} message SettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SettingsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.llmModel != null && Object.hasOwnProperty.call(message, "llmModel"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.llmModel);
                    if (message.llmSystemPrompt != null && Object.hasOwnProperty.call(message, "llmSystemPrompt"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.llmSystemPrompt);
                    if (message.llmBackend != null && Object.hasOwnProperty.call(message, "llmBackend"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.llmBackend);
                    return writer;
                };

                /**
                 * Encodes the specified SettingsProto message, length delimited. Does not implicitly {@link space.kenko.proto.SettingsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {space.kenko.proto.ISettingsProto} message SettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SettingsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SettingsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.SettingsProto} SettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SettingsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.SettingsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.llmModel = reader.string();
                                break;
                            }
                        case 2: {
                                message.llmSystemPrompt = reader.string();
                                break;
                            }
                        case 3: {
                                message.llmBackend = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SettingsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.SettingsProto} SettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SettingsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SettingsProto message.
                 * @function verify
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SettingsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.llmModel != null && message.hasOwnProperty("llmModel"))
                        if (!$util.isString(message.llmModel))
                            return "llmModel: string expected";
                    if (message.llmSystemPrompt != null && message.hasOwnProperty("llmSystemPrompt"))
                        if (!$util.isString(message.llmSystemPrompt))
                            return "llmSystemPrompt: string expected";
                    if (message.llmBackend != null && message.hasOwnProperty("llmBackend"))
                        if (!$util.isString(message.llmBackend))
                            return "llmBackend: string expected";
                    return null;
                };

                /**
                 * Creates a SettingsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.SettingsProto} SettingsProto
                 */
                SettingsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.SettingsProto)
                        return object;
                    var message = new $root.space.kenko.proto.SettingsProto();
                    if (object.llmModel != null)
                        message.llmModel = String(object.llmModel);
                    if (object.llmSystemPrompt != null)
                        message.llmSystemPrompt = String(object.llmSystemPrompt);
                    if (object.llmBackend != null)
                        message.llmBackend = String(object.llmBackend);
                    return message;
                };

                /**
                 * Creates a plain object from a SettingsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {space.kenko.proto.SettingsProto} message SettingsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SettingsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.llmModel = "";
                        object.llmSystemPrompt = "";
                        object.llmBackend = "";
                    }
                    if (message.llmModel != null && message.hasOwnProperty("llmModel"))
                        object.llmModel = message.llmModel;
                    if (message.llmSystemPrompt != null && message.hasOwnProperty("llmSystemPrompt"))
                        object.llmSystemPrompt = message.llmSystemPrompt;
                    if (message.llmBackend != null && message.hasOwnProperty("llmBackend"))
                        object.llmBackend = message.llmBackend;
                    return object;
                };

                /**
                 * Converts this SettingsProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.SettingsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SettingsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SettingsProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.SettingsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SettingsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.SettingsProto";
                };

                return SettingsProto;
            })();

            return proto;
        })();

        return kenko;
    })();

    return space;
})();

module.exports = $root;
