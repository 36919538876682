import React, {createContext, ReactElement, useEffect, useState} from 'react';
import {CheckAdminRoleRequest, CheckAdminRoleResponse} from './compiled';
import {useAuth0} from "@auth0/auth0-react";
import {SendRpc} from "./rpcSender";

export const AdminProfileContext = createContext<{
  profile: CheckAdminRoleResponse | null,
  isLoading: boolean,
  profileError: string | null,
}>({
  profile: null,
  isLoading: true,
  profileError: null,
});

interface Props {
  children?: ReactElement
}

/**
 * This should return the proto configuration for this unicorn.
 * It contains all the unicorn's special characteristics, like
 * the logo, description, which modules are on the website, etc.
 *
 *
 * @return {JSX.Element}
 * @constructor
 */
export const AdminProfileProvider = (props: Props) => {

  const {user, getIdTokenClaims} = useAuth0();

  const [userProfile, setUserProfile] = useState<CheckAdminRoleResponse | null>(null);
  const [profileLoading, setProfileLoading] = useState<boolean>(false);
  const [profileError, setProfileError] = useState<string | null>(null);

  useEffect(() => {

    if (!user) {
      setUserProfile(null);
      setProfileLoading(false);
      setProfileError(null);
      return;
    }

    // Download it immediately at the beginning...
    console.log('[Fetching user profile...]');
    setUserProfile(null);
    setProfileLoading(true);
    setProfileError(null);

    SendRpc(getIdTokenClaims, "check_admin_role",
        CheckAdminRoleRequest.encode(new CheckAdminRoleRequest()).finish())
        .then(response => {
          let profileResponse = CheckAdminRoleResponse.decode(response);
          console.log("Admin profile: " + JSON.stringify(profileResponse.toJSON()))
          setUserProfile(profileResponse);
        })
        .catch(error => {
          setProfileError(error)
        })
        .finally(() => {
          setProfileLoading(false);
        });

  }, [user]);

  return (<AdminProfileContext.Provider value={{
    profile: userProfile,
    isLoading: profileLoading,
    profileError: profileError
  }}>
    {props.children}
  </AdminProfileContext.Provider>);
};
