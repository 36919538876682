import React, {FormEvent, useEffect, useState} from "react";
import {
  GenerateInvitationRequest,
  GenerateInvitationResponse,
  GetSettingsRequest,
  GetSettingsResponse,
  space, UpdateSettingsRequest
} from "../../../compiled";
import {SendRpc} from "../../../rpcSender";
import {useAuth0} from "@auth0/auth0-react";
import SettingsProto = space.kenko.proto.SettingsProto;
import ISettingsProto = space.kenko.proto.ISettingsProto;

export const SettingsTab = () => {

  const {getIdTokenClaims} = useAuth0();

  // The RPC callback from generated an invite. Setting this as state
  // causes it to render a message
  const [settings, setSettings] = useState<SettingsProto>();

  const [newSettings, setNewSettings] = useState<string>();

  const [updateResponse, setUpdateResponse] = useState('');

  // Fetch settings on load.
  useEffect(() => {
    let request = GetSettingsRequest.encode(new GetSettingsRequest()).finish();

    SendRpc(getIdTokenClaims, 'action/get_settings', request).then(r => {
      let response = GetSettingsResponse.decode(r);
      console.log('get settings response', JSON.stringify(response));
      if (response.okay && response.newSettings) {
        setSettings(response.newSettings as SettingsProto);
        setNewSettings(JSON.stringify(response.newSettings, null, 2));
      } else {
        setUpdateResponse('settings error');
      }
    }).catch(e => {
      console.log(e);
      setUpdateResponse('settings error ' + e)
    })
  }, []);

  const generateInvites = function (event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!newSettings) {
      return;
    }

    let parsed;
    try {
      parsed = JSON.parse(newSettings);
    } catch (e) {
      alert('parse error: ' + e)
      return;
    }

    var settings = new SettingsProto(parsed)

    if (!window.confirm('update with these settigns?\n' + JSON.stringify(settings))) {
      return;
    }

    let request = UpdateSettingsRequest.encode(
        new UpdateSettingsRequest({
          settings: settings
        })).finish();

    SendRpc(getIdTokenClaims, 'action/update_settings', request).then(r => {
      let response = GetSettingsResponse.decode(r);
      if (response.okay && response.newSettings) {
        console.log('update settings success', JSON.stringify(response.newSettings));
        setSettings(response.newSettings as SettingsProto);
        setUpdateResponse('settings updated!');

      } else {
        setUpdateResponse('settings error');
      }
    }).catch(e => {
      console.log(e);
      setUpdateResponse('settings error ' + e)
    })
  };

  if (!newSettings) {
    return <div>Loading latest settings...</div>
  }

  return (
      <>
        <div>
          <b>Settings Admin</b>
          <br/>
          <br/>
        </div>

        <div>
          <form onSubmit={generateInvites}>
            <div>
              <textarea rows={12} cols={64} value={newSettings}
                        onChange={e => setNewSettings(e.target.value)}/>
            </div>
            <button type="submit" value="Submit">Update settings</button>
          </form>

          {updateResponse && <div>{updateResponse}</div>}
        </div>
      </>
  );
}
