/* global google */

import React, {useContext} from 'react';
import './App.css';
import {useAuth0} from "@auth0/auth0-react";
import {AdminProfileContext} from "../AdminProfileProvider";
import {AdminHome} from "./AdminRoot/AdminHome";
import {Header} from "./Header";

/**
 * This is the parent of everything on the site. It requires a login and then
 * checks that the user is actually an admin. If not, then it shows an error.
 */
export const App = () => {

  const {isLoading, user, loginWithRedirect} = useAuth0();

  const profile = useContext(AdminProfileContext);

  function auth0Login() {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname
      },
      authorizationParams: {
        audience: `https://narwhal.kenko.space/`,
        scope: 'openid offline_access' // to get refresh token
      }
    });
  }
  
  // Don't render anything unless the user is authenticated AND authorized as an admin.
  // Obviously the javascript is still public but at least this is better than nothing.

  if (isLoading) {
    return <div>loading user...</div>
  }

  if (!user) {
    auth0Login();
  }

  if (profile.isLoading) {
    return <div>loading profile...</div>
  }
  
  if (!profile.profile?.isAdmin) {
    return <div>403</div>
  }

  return (
      <div className="App">
        <Header/>
        <div className='Body'>

          <div style={{
            flex: 1,
            minHeight: 300,
            minWidth: 500,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 10,
            marginTop: 20,
          }}>
            
            <AdminHome/>
          </div>
        </div>


        <div className={'Footer}'}>
          copyright 2024 kenko
          <br/>
          <a href={window.location.protocol + '//provider.' + window.location.host}>provider login</a>
        </div>
      </div>
  );
}

export default App;
